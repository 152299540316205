export const institutionCodes = [
  { code: "AKPM", label: "Akita Prefectural Museum" },
  { code: "AM", label: "Australian Museum" },
  { code: "ANSP", label: "Academy of Natural Sciences at Philidelphia" },
  { code: "AUM", label: "Auburn University Museum of Natural History" },
  { code: "BPBM", label: "Bernice Pauahi Bishop Museum, Department of Natural Sciences" },
  { code: "CAS", label: "California Academy of Sciences" },
  { code: "CBM", label: "Natural History Museum and Institute, Chiba" },
  { code: "CMN", label: "Canadian Museum of Nature" },
  { code: "CSIRO", label: "CSIRO" },
  { code: "CUMV", label: "Cornell University Museum of Vertebrates" },
  { code: "DGR", label: "Arctos - DGR Fishes" },
  { code: "FHSM", label: "Fort Hays Sternberg Museum of Natural History" },
  { code: "FMNH", label: "Field Museum" },
  { code: "FRLM", label: "Fisheries Research Laboratory Mie University" },
  { code: "FSBC", label: "Florida Fish and Wildlife Conservation Commission" },
  { code: "GMNHJ", label: "Gunma Museum of Natural History" },
  { code: "HUMZ", label: "Hokkaido University" },
  { code: "IBUNAM", label: "Universidad Nacional Autonoma de Mexico - IBiologia - CNPE/Coleccion Nacional de Peces" },
  { code: "INHS", label: "Illinois Natural History Survey" },
  { code: "INM", label: "Ibaraki Nature Museum" },
  { code: "KAUM", label: "Kagoshima University Museum" },
  { code: "KPM", label: "Kanagawa Prefectural Museum of Natural History" },
  { code: "KSNHM", label: "Kishiwada City Museum of Natural History" },
  { code: "KU", label: "University of Kansas Biodiversity Institute" },
  { code: "LACM", label: "Los Angeles County Museum of Natural History" },
  { code: "LBM", label: "Lake Biwa Museum" },
  { code: "LSUMZ", label: "Louisiana State University Museum of Zoology" },
  { code: "MCZ", label: "Harvard University Museum of Comparative Zoology" },
  { code: "MMNS", label: "Mississippi Museum of Natural Science" },
  { code: "MNHAH", label: "Museum of Nature and Human Activities, Hyogo" },
  { code: "MNHN", label: "GBIF-MNHN (Paris)" },
  { code: "MSB", label: "Museum of Southwestern Biology" },
  { code: "MSUM", label: "Michigan State University Museum" },
  { code: "MVZ", label: "Arctos - MVZ Hildebrand Specimens" },
  { code: "NCSM", label: "North Carolina State Museum of Natural Sciences" },
  { code: "NMV", label: "Museum Victoria" },
  { code: "NRM", label: "Swedish Museum of Natural History" },
  { code: "NSMT", label: "Japanese National Museum of Nature and Science" },
  { code: "NT", label: "Northern Territory Museum and Art Gallery" },
  { code: "OMNH", label: "Osaka Museum of Natural History" },
  { code: "OS", label: "Oregon State University" },
  { code: "OSUM", label: "Ohio State University" },
  { code: "PUJ", label: "Colección de peces del Museo de Historia Natural de la Pontificia Universidad Javeriana" },
  { code: "QM", label: "Queensland Museum" },
  { code: "QVMAG", label: "Queen Victoria Museum Art Gallery" },
  { code: "ROM", label: "Royal Ontario Museum" },
  { code: "SAMA", label: "South Australian Museum" },
  { code: "SBMNH", label: "Santa Barbara Museum of Natural History" },
  { code: "SIO", label: "Scripps Institute of Oceanography" },
  { code: "SNM", label: "Sam Noble Oklahoma Museum of Natural History" },
  { code: "TCWC", label: "Texas A&M University Biodiversity Research and Teaching Collections" },
  { code: "TG", label: "Taga Town Museum" },
  { code: "TKPM", label: "Museum of Tokushima Prefecture" },
  { code: "TMAG", label: "Tasmanian Museum and Art Gallery" },
  { code: "TNHC", label: "Texas Natural History Science Center (TNHC)" },
  { code: "TU", label: "Tulane University Museum of Natural History" },
  { code: "UA", label: "University of Alabama Ichthyological Collection" },
  { code: "UAFMC", label: "University of Arkansas Collections Facility" },
  { code: "UAM", label: "Arctos - UAM" },
  { code: "UAMZ", label: "University of Alberta" },
  { code: "UCM", label: "University of Colorado Museum of Natural History" },
  { code: "UF", label: "Florida Museum of Natural History" },
  { code: "UMZC", label: "University Museum of Zoology Cambridge (UMZC)" },
  { code: "UMMZ", label: "University of Michigan Museum of Zoology" },
  { code: "UNSM", label: "University of Nebraska State Museum" },
  { code: "USNM", label: "National Museum of Natural History, Smithsonian Institution" },
  { code: "UT", label: "University of Tennessee-Etnier Ichthyological Research Collection" },
  { code: "UWFC", label: "University of Washington Fish Collection" },
  { code: "WNMU", label: "Arctos - WNMU Fishes Specimens" },
  { code: "YCM", label: "Yokosuka City Museum" },
  { code: "YPM", label: "Yale University Peabody Museum" },
  { code: "ZMUC", label: "Natural History Museum of Denmark" }
];
